import './destinations.css'

function DestinationCard(props) {
  return (
    <div className='card' onClick={props.clickHandle}>
      <img className='img-card' src={props.imageUrl} alt=''/>
      {props.isMobile?<p style={{visibility: 'visible'}} className='text'>{props.name}</p>:<p className='text'>{props.name}</p>}
    </div>
  );
}

export default DestinationCard;
