import {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

function SpotDescription(props){
    useEffect(() => {
        AOS.init();
    }, [])
    
    const description=props.description.split('\n').map((text,ind)=><p key={ind} data-aos='fade-up'>{text}</p>)
    const locationAccess=props.locationAccess.split('\n').map((text,ind)=><p key={ind} data-aos='fade-up'>{text}</p>)
    const stayOptions=props.stayOptions.split('\n').map((text,ind)=><p key={ind} data-aos='fade-up'>{text}</p>)
    const activities=props.activities.split('\n').map((text,ind)=><p key={ind} data-aos='fade-up'>{text}</p>)


    const styles={
        headings: {
            fontWeight: 'bold',
            textTransform: 'capitalize'
        },
        container: {
            width: '90%',
            height: '100%',
            padding: '20px',
            margin: 'auto',
            textAlign: 'justify'
        },
    }
    return  <div style={styles.container}>
                <p data-aos='slide-up' style={styles.headings}>About {props.title}:</p>
                {description}
                <p data-aos='slide-up' style={styles.headings}>Location access:</p>
                {locationAccess}
                { props.stayOptions.length>0?<><p data-aos='slide-up' style={styles.headings}>Stay options:</p>
                {stayOptions}</>:<></>}
                <p data-aos='slide-up' style={styles.headings}>Activities:</p>
                {activities}
            </div>
}

export default SpotDescription