import Carousel from 'react-img-carousel';
import './react-img-carousel.css'
import { useState,useEffect} from 'react';

require('react-img-carousel/lib/carousel.css');

const ReactImgCarousel=(props)=>{
    const [carouselHeight,setCarouselHeight]=useState('550px')
    const updateCarouselHeight=()=>{
        window.innerWidth<650?setCarouselHeight('400px'):setCarouselHeight('550px')
    }
    useEffect(()=>{
        window.addEventListener('resize',updateCarouselHeight);
        return ()=>{
            window.removeEventListener('resize',updateCarouselHeight)
        }
    })
    const data=props.data.map((pic,ind)=>{
        return (
            <div style={{height: carouselHeight, display: 'flex'}} className='carousel-img-div'> 
                <img className='carousel-img' src={pic.src} />
            </div>)
    })

    const width=window.innerWidth<650;

    return (
        <Carousel height={carouselHeight} slideHeight={carouselHeight} slideWidth='100%' lazyLoad='true' cellPadding={ 5 }>
            {data}
        </Carousel>)
}

export default ReactImgCarousel